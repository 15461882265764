import React, { useEffect } from 'react'
import { PageProps } from 'gatsby'
import { Head, Banner, DefaultContainer, Footer, Menu } from '~/components'
import { useApi } from '~/siteApi'
import { useContext } from '~/hooks'
import imgBanner from '../assets/img/img-unidade-regional.jpg'
import imgBannerMobile from '../assets/img/img-unidade-regional-mobile.jpg'

const Cooperativa = ({
  location,
  pageContext = {},
  '*': slug,
}: PageProps & { '*'?: string }) => {
  const context = useApi(
    pageContext,
    `page-benefits/${(pageContext as ApiBenefits).department?.slug || slug}`
  ) as ApiBenefits

  const { name: title } = context?.department || {}

  const { setLoading } = useContext()
  useEffect(() => {
    setLoading(!title)
  }, [title])

  const benefits = context?.benefits

  const ApiMedicalPlan = useApi(
    {
      primer: {},
    },
    'page-departments'
  )

  return title ? (
    <>
      <Head location={location} />
      <Menu location={location} />
      <main>
        <Banner
          title={title}
          text={'Acesse aqui as informações sobre seus benefícios:'}
          img={imgBanner}
          imgMobile={imgBannerMobile}
          ctaList={benefits.map(({ name, slug }) => ({
            name,
            slug: `${context.department.slug}/${slug}`,
            color: '#FFF',
          }))}
        />
        {ApiMedicalPlan && (
          <DefaultContainer
            title={ApiMedicalPlan.primer.text}
            textCta={'Faça aqui o download'}
            slug={ApiMedicalPlan.primer.file}
          />
        )}
      </main>
      <Footer location={location} />
    </>
  ) : (
    <></>
  )
}

export default Cooperativa
