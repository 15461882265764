// extracted by mini-css-extract-plugin
export var active = "Home-module--active--16570";
export var autocomplete = "Home-module--autocomplete--9a8f1";
export var btnAcess = "Home-module--btn-acess--46b12";
export var btnOpenInput = "Home-module--btn-open-input--f016b";
export var containerMenuNav = "Home-module--container-menu-nav--08fd8";
export var containerPrincipal = "Home-module--containerPrincipal--a8b8c";
export var containerReasons = "Home-module--container-reasons--02ec5";
export var containerSearch = "Home-module--container-search--94dd4";
export var contentMobile = "Home-module--content-mobile--a2528";
export var core = "Home-module--core--ad2ec";
export var logoJuntos = "Home-module--logoJuntos--f5085";
export var logoTop = "Home-module--logo-top--2799f";
export var reasonsList = "Home-module--reasons-list--68560";