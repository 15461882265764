import React, { useState, useEffect } from 'react'
import { PageProps, Link, navigate } from 'gatsby'
import api from '../siteApi/default'
import { useContext } from '~/hooks'
import { Head, Footer, Menu } from '~/components'
import * as st from '~/assets/styl/PageDefault.module.styl'
import * as stDataPrincipal from '~/assets/styl/EntregaveisDetalheBusca.module.styl'
import nl2br from 'react-nl2br'
import cn from 'classnames'

const BeneficioDetalhe = ({
  location,
  '*': slug,
}: PageProps & { '*'?: string }) => {
  const { setLoading } = useContext()

  const [pageData, setPageData] = useState<ApiBenefitContent>()
  useEffect(() => {
    setLoading(true)
    const url = slug ? `/${slug}` : location.pathname

    const fetchData = async () => {
      try {
        const { department, benefit, deliverable } = await api(
          'get',
          `page-deliverables${url}`
        )
        const data = {
          department,
          benefit,
          deliverable,
        }
        setPageData(data)
        setLoading(false)
      } catch (error) {
        console.error(error)
        navigate('/404')
      }
    }

    fetchData()
  }, [])

  return pageData ? (
    <>
      <Head location={location} />
      <Menu location={location} />
      <main className={st.core}>
        <div className={st.imgText} style={{ color: pageData.benefit.color }}>
          <img src={pageData.deliverable.image} alt="" />

          <div>
            <p>{pageData.benefit.name}</p>
            <section>
              <h1 className="title medium-size color-white">
                {nl2br(pageData.deliverable.title)}
              </h1>
              {pageData.deliverable.resume && (
                <p>{pageData.deliverable.resume}</p>
              )}
              <Link to="?#saiba-mais">Saiba Mais</Link>
            </section>
          </div>
        </div>
        <div className={cn(stDataPrincipal.core, 'container')} id="saiba-mais">
          {pageData.deliverable.contents.map(
            ({ title, description, icon }, index) => (
              <section key={index}>
                <h3
                  className={icon && stDataPrincipal.icon}
                  style={{
                    color: `${pageData.benefit.color}`,
                  }}
                >
                  {' '}
                  {icon && (
                    <span
                      style={{
                        maskImage: `url(${icon})`,
                        WebkitMaskImage: `url(${icon})`,
                      }}
                    ></span>
                  )}
                  {title}
                </h3>
                {description && (
                  <div dangerouslySetInnerHTML={{ __html: description }}></div>
                )}
              </section>
            )
          )}
        </div>
      </main>
      <Footer location={location} />
    </>
  ) : (
    <></>
  )
}

export default BeneficioDetalhe
